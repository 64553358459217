import styled from '@emotion/styled';

export const HamburgerContainer = styled.div(props => ({
	position: 'absolute',

	'& .dark_background': {
		position: 'fixed',
		width: '100%',
		height: '100vh',
		background: 'rgba(0, 0, 0, 0.38)',
		backdropFilter: 'blur(1px)',
		top: '0',
		left: '0',
		cursor: 'pointer',

		'&.active': {
			animation: 'FADE_IN 0.4s ease',
		},
		'&.deactive': {
			animation: 'FADE_OUT 0.4s ease',
		},
	},

	aside: {
		position: 'fixed',
		top: '0',
		width: '320px',
		backgroundColor: 'white',
		height: '100vh',
		padding: '16px',
		zIndex: '1005',
		right: '0',
		transition: 'all 0.5s cubic-bezier(0, 0, 0.2, 1) 0s',

		'&.active': {
			animation: 'SLIDE_IN 0.4s ease',
		},
		'&.deactive': {
			animation: 'SLIDE_OUT 0.4s ease',
		},

		'& .header': {
			width: '100%',
			display: 'flex',
			flexDirection: 'column',

			img: {
				transform: 'rotate(180deg)',
				margin: '0 auto 16px 16px',
			},

			button: {
				borderRadius: '4px',
				marginBottom: '8px',
				fontWeight: '500',
				border: '1px solid black',

				'&.open_account_btn': {
					border: 'none',
					color: '#333333',
				},
			},
		},

		'& .menu': {
			marginTop: '16px',

			'& .item': {
				padding: '14px 16px 15px',
				cursor: 'pointer',
				color: '#333333',
				fontWeight: '400',

				'&:hover': {
					borderRadius: '8px',
					background: '#FFF9EB',
				},
			},
		},
	},

	'& .divider': {
		border: '1px solid #E5E7EA',
		margin: '8px 0',
	},

	'& .download_container': {
		display: 'flex',
		width: '100%',
		justifyContent: 'space-between',
		padding: '12px 20px 12px 12px',
		background: '#f6f6fe',
		marginTop: '28px',
		borderRadius: '4px',

		'& .left': {
			display: 'grid',
			height: '40px',
			gridTemplate: '1fr auto / 40px 1fr',
			gap: '0 12px',

			img: {
				gridColumn: '1',
				gridRow: '1/3',
			},

			'& .bottom_download_title': {
				fontSize: '0.875rem',
				color: '#000000',
				fontWeight: '700',
				lineHeight: '17px',
				margin: 'auto auto auto 0',
			},
			'& .bottom_download_subtitle': {
				fontSize: '0.75rem',
				color: '#464F58',
				fontWeight: '300',
				lineHeight: '15px',
				margin: 'auto auto auto 0',
			},
		},
		'& .right': {
			display: 'flex',
			gap: '24px',

			'& .bottom_download_right_item': {
				display: 'flex',
				justifyContent: 'center',
				alignItems: 'center',
				cursor: 'pointer',
			},
		},
	},
}));

export const HumburgerItemContainer = styled.div(props => ({
	'& .title_dropdown': {
		cursor: 'pointer',
		display: 'flex',
		justifyContent: 'space-between',
		height: '48px',
		padding: '0 16px',
		alignItems: 'center',
		span: {
			fontWeight: '300',
			color: 'black',
		},

		i: {
			fontSize: '1.2rem',
		},

		'&.active': {
			background: '#F7F7F7',
			borderRadius: '4px',
		},
	},

	'& .simple_title': {
		display: 'flex',
		alignItems: 'center',
		color: 'black',
		fontWeight: '300',
		height: '48px',
		padding: '0 16px',
		'&:hover': {
			background: '#F7F7F7',
			borderRadius: '4px',
		},
	},

	'& .sub_menu': {
		transition: ' all 0.5s cubic-bezier(0, 0, 0.2, 1) 0s',
		height: '0px',
		overflow: 'hidden',

		'&.open': {
			height: 'fit-content',
		},

		a: {
			height: '40px',
			display: 'flex',
			alignItems: 'center',
			color: '#464F58',
			paddingLeft: '32px',
			fontWeight: '300',
			fontSize: '0.875rem',
			transition: 'all 0.1s linear 0s',
		},
	},
}));
