import styled from '@emotion/styled';

export const NavbarContainer = styled.nav(props => ({
	display: 'flex',
	flexDirection: 'column-reverse',
	position: 'sticky',
	width: '100%',
	zIndex: '90',
	top: '0',

	'& .MuiSelect-select': {
		textTransform: 'capitalize',
		fontWeight: '400',
	},

	'& .MuiInputBase-root': {
		fieldset: {
			border: 'none',
		},
	},

	'& .dark_container': {
		height: '48px',
		background: 'black',
		width: '100%',
		padding: '0 16px',
		display: 'grid',
		alignItems: 'center',
		gridTemplateColumns: '1fr 1fr',
		gap: '8px',

		a: {
			borderRadius: '4px',
			height: '32px',
			fontWeight: '400',
			fontSize: '0.875rem',
			border: '1px solid white',
			padding: '7px 10px',

			'&.login': {
				color: 'white',
			},

			'&.open_live': {
				color: 'black',
				background: 'white',
			},

			'&.ghosted': {
				display: 'none',
			},
		},
	},

	'& .light_container': {
		width: '100%',
		height: '48px',
		padding: '0 16px',
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'space-between',
		backgroundColor: '#fff',

		'& .left_container': {
			display: 'none',
		},

		'& .right_side': {
			display: 'flex',
			alignItems: 'center',
			gap: '20px',
		},
	},

	'& .left_field': {
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'space-between',
		width: '100%',
		height: '100%',
	},

	'& .desktop_field': {
		display: 'none',
	},

	'@media(min-width:576px)': {
		flexDirection: 'column',

		'& .dark_container': {
			padding: '0 40px',
			display: 'flex',
			gap: '4px',
			justifyContent: 'flex-end',

			a: {
				padding: '0',
				minWidth: '0',

				'&.login': {
					width: '89px',
				},

				'&.open_live': {
					width: '89px',
					minWidth: 'max-content',
					padding: '0 10px',
				},

				'&.ghosted': {
					border: 'none',
					display: 'flex',
					color: 'white',
					fontWeight: '400',
					fontSize: '0.875rem',
					margin: props.theme.direction === 'rtl' ? '0 0 0 20px' : '0 20px 0 0',
					gap: '8px',
				},
			},
		},

		'& .light_container': {
			width: '100%',
			height: '48px',
			padding: '0 16px',
			display: 'flex',
			alignItems: 'center',
			justifyContent: 'space-between',
			backgroundColor: '#fff',

			'& .right_data': {
				display: 'none',
			},
		},
	},

	'@media(min-width:1160px)': {
		'& .light_container': {
			height: '64px',
			padding: '0 40px',
			alignItems: 'center',
			justifyContent: 'space-between',
			gap: '15px',

			'& .right_side': {
				display: 'none',
			},

			'& .left_container': {
				display: 'flex',
				gap: '20px',
				height: '100%',
			},
		},

		'& .left_field': {
			display: 'flex',
			gap: '20px',
			width: 'max-content',

			img: {
				width: '120px',
			},
		},

		'& .desktop_field': {
			display: 'flex',
			alignItems: 'center',
			fontWeight: '400',

			p: {
				position: 'relative',
				right: '-9px',
			},
		},
	},

	'@media(min-width:1280px)': {
		'& .light_container': {
			gap: '32px',

			'& .left_container': {
				gap: '32px',
			},
		},

		'& .left_field': {
			gap: '32px',

			img: {
				width: 'unset',
			},
		},
	},
}));

export const NavbarItemContainer = styled.div(props => ({
	position: 'relative',
	display: 'flex',
	alignItems: 'center',
	cursor: 'pointer',

	'& .title_dropdown': {
		display: 'flex',
		gap: '6px',
		color: 'black',
		alignItems: 'center',

		span: {
			fontWeight: '400',
			fontSize: '0.875rem',
		},

		i: {
			fontSize: '1.2rem',
			marginTop: '2px',
		},
	},

	'& .simple_title': {
		fontWeight: '400',
		fontSize: '0.875rem',
		color: 'black',
	},

	'& .sub_menu': {
		position: 'absolute',
		zIndex: '1',
		top: '64px',
		left: '0px',
		opacity: '0',
		pointerEvents: 'none',
		backgroundColor: 'white',
		padding: '12px',
		borderRadius: '4px',
		boxShadow: 'rgba(0, 0, 0, 0.1) 0px 2px 8px 0px',
		width: '225px',
		transition: 'all 0.1s linear 0s',
		maxHeight: 'calc(100vh - 90px)',
		overflowY: 'auto',
		display: 'flex',
		flexDirection: 'column',
		minWidth: '160px',

		a: {
			color: 'black',
			height: '40px',
			display: 'flex',
			alignItems: 'center',
			padding: '0 12px',
			cursor: 'pointer',
			fontWeight: '300',
			fontSize: '0.875rem',
			borderRadius: '4px',

			'&:hover': {
				background: '#F7F7F7',
				fontWeight: '400',
			},
		},
	},

	'&:hover': {
		'& .sub_menu': {
			opacity: '1',
			pointerEvents: 'inherit',
		},
		'& .fa-angle-down': {
			transform: 'rotateX(-180deg)',
			top: '-2px',
		},
	},
}));
