import styled from '@emotion/styled';

export const FooterMainField = styled.footer(props => ({
	width: '100%',
	background: 'var(--color-background-section-1)',
	padding: '28px 0 28px 0',
	borderTop: '1px solid #E0E2E6',
	display: 'flex',
	alignItems: 'center',
	flexDirection: 'column',

	'@media(min-width:992px)': {
		padding: '40px 0 32px 0',
	},

	'& .footer_flex_container': {
		display: 'flex',
		flexDirection: 'column',
		width: '100%',

		// '@media (min-width: 576px)': {
		// 	width: '540px',
		// },

		'@media (min-width: 768px)': {
			display: 'flex',
		},

		// '@media (min-width: 992px)': {
		// 	width: '960px',
		// },

		// '@media (min-width: 1200px)': {
		// 	width: '1140px',
		// },

		// '@media (min-width: 1400px)': {
		// 	width: '1320px',
		// },

		'& .footer_container': {
			paddingBottom: '0.75rem',
			margin: '0 1rem',
			borderBottom: '1px solid #E5E7EA',
			display: 'flex',
			flexDirection: 'column',
			order: '1',
			'@media (min-width: 576px)': {
				width: '540px',
				margin: '0 auto',
				paddingBottom: '0',
				borderBottom: 'none',
			},

			'@media (min-width: 768px)': {
				width: '720px',
			},

			'@media (min-width: 992px)': {
				width: '960px',
				flexDirection: 'row',
				justifyContent: 'space-between',
			},

			'@media (min-width: 1200px)': {
				width: '1140px',
			},

			'@media (min-width: 1400px)': {
				width: '1320px',
			},

			'& .column_container': {
				display: 'flex',
				flexDirection: 'column',

				'& .footer_links_container': {
					display: 'none',

					'&.active': {
						display: 'flex',
						flexDirection: 'column',
						gap: '16px',
						marginBottom: '12px',
						paddingLeft: '16px',
						'@media(min-width:992px)': {
							marginTop: '0',
							paddingLeft: '0',
						},
					},

					'@media(min-width:992px)': {
						display: 'flex',
						gap: '16px',
						flexDirection: 'column',
					},
				},
				'& .column_header': {
					display: 'flex',
					justifyContent: 'space-between',
					h4: {
						marginBottom: '1.5rem',
						fontWeight: 500,
						fontSize: '1rem',
						lineHeight: '20px',

						'@media(min-width:992px)': {
							marginBottom: '24px',
							fontWeight: 700,
						},
					},

					'@media(min-width:576px)': {
						img: {
							width: '16px',
						},
					},
					'@media(min-width:992px)': {
						img: {
							display: 'none',
						},
					},
				},

				'& .footer_item': {
					color: 'var(--color-text-2)',
					fontSize: '0.875rem',
					fontWeight: '300',
					cursor: 'pointer',
				},

				'& .footer_img': {
					height: '35px',
					'@media(min-width:992px)': {
						marginBottom: '24px',
					},
				},

				'&.column_logo': {
					order: '1',
					alignItems: 'flex-start',

					'@media(min-width:992px)': {
						order: '7',
						alignItems: 'center',
					},
				},

				'&.column_company': {
					order: '2',
					margin: '32px 0 24px',

					'@media(min-width:992px)': {
						margin: '0',
					},
				},

				'&.column_products': {
					order: '3',
					margin: '0 0 24px',
					'@media(min-width:992px)': {
						margin: '0',
					},
				},

				'&.column_support': {
					order: '4',
					margin: '0 0 24px',
					'@media(min-width:992px)': {
						margin: '0',
					},
				},

				'&.column_services': {
					order: '5',
					margin: '0 0 0',
					'@media(min-width:576px)': {
						margin: '0 0 24px',
					},
					'@media(min-width:992px)': {
						margin: '0',
					},
				},

				'&.column_buy_crypto': {
					order: '6',
					display: 'none',
					'@media(min-width:992px)': {
						display: 'flex',
					},
				},

				'& .footer_qr_container': {
					display: 'none',

					'@media(min-width:992px)': {
						display: 'flex',
						flexDirection: 'column',
						alignItems: 'center',
						gap: '8px',
						'& .footer_qr_square': {
							width: '144px',
							height: '144px',
							border: '1px solid #000000',
							borderRadius: '24px',
							display: 'flex',
							alignItems: 'center',
							justifyContent: 'center',
							padding: '10px',

							img: {
								width: '-webkit-fill-available',
								borderRadius: '12px',
							},
						},
						'& .footer_qr_text': {
							fontWeight: '300',
							color: 'var(--color-text-1)',
							fontSize: '0.625rem',
						},
					},
				},
			},
		},

		'& .footer_divider': {
			width: '100%',
			height: '2px',
			background: 'radial-gradient(black 20%, transparent 80%)',
			position: 'relative',
			marginBottom: '25px',
			order: '4',

			'@media(min-width:576px)': {
				order: '3',
				marginBottom: '16px',
			},

			img: {
				position: 'absolute',
				bottom: '-18.8px',
				right: '40px',
				'@media(min-width:576px)': {
					right: '40%',
					width: '24px',
					bottom: '-11px',
				},

				'@media(min-width:768px)': {
					right: '130px',
				},
			},
		},

		'& .footer_copy_socials': {
			order: '5',
			display: 'flex',
			marginTop: '1rem',
			flexDirection: 'column',
			alignSelf: 'center',
			'.holder': {
				display: 'none',
				'@media(min-width:576px)': {
					display: 'flex',
					flex: '1 0 0%',
				},
			},
			'.footer_socials_container': {
				display: 'flex',
				gap: '12px',
				justifyContent: 'center',

				'@media(min-width:576px)': {
					flex: '1 0 0%',
					justifyContent: 'flex-start',
				},

				'& .social_items': {
					display: 'flex',
					gap: '12px',
				},
			},

			'.footer_copyright': {
				display: 'flex',
				justifyContent: 'center',
				color: 'var(--color-text-2)',

				fontWeight: '300',
				fontSize: '0.875rem',
				lineHeight: '17px',
				marginTop: '1rem',
			},
			'@media (min-width: 576px)': {
				width: '540px',
				flexDirection: 'row',
				justifyContent: 'space-between',
				alignItems: 'center',
				flex: '1 0 0%',
				'.footer_copyright': {
					justifyContent: 'flex-start',
					marginTop: 0,
				},
			},

			'@media (min-width: 768px)': {
				width: '720px',
			},

			'@media (min-width: 992px)': {
				width: '960px',
			},

			'@media (min-width: 1200px)': {
				width: '1140px',
			},

			'@media (min-width: 1400px)': {
				width: '1320px',
			},
		},

		'& .risk_container': {
			order: '3',
			padding: '1rem',
			backgroundColor: '#2D3339',

			'@media(min-width:576px)': {
				padding: '24px 0 36px',
			},
			'@media(min-width:992px)': {
				padding: '24px 0 44px',
			},

			'& .risk_title': {
				color: '#E5E7EA',
				marginBottom: '16px',
				fontSize: '1rem',
				lineHeight: '20px',

				fontWeight: '500',

				'@media(min-width:992px)': {
					fontSize: '1.125rem',
					lineHeight: '22px',
				},
			},

			'.disclaimer': {
				'@media (min-width: 576px)': {
					width: '540px',
					margin: '0 auto',
				},

				'@media (min-width: 768px)': {
					width: '720px',
				},

				'@media (min-width: 992px)': {
					width: '960px',
				},

				'@media (min-width: 1200px)': {
					width: '1140px',
				},

				'@media (min-width: 1400px)': {
					width: '1320px',
				},
			},

			p: {
				fontSize: '0.875rem',
				lineHeight: props.theme.direction === 'rtl' ? '25px' : '17px',
				color: '#A7B0B8',
			},
		},

		'& .forex_container': {
			order: '2',
			padding: '1rem',

			'@media (min-width: 576px)': {
				width: '540px',
				padding: '24px 0 36px',
				margin: '0 auto',
			},

			'@media (min-width: 768px)': {
				width: '720px',
			},

			'@media (min-width: 992px)': {
				padding: '24px 0 44px',
				width: '960px',
			},

			'@media (min-width: 1200px)': {
				width: '1140px',
			},

			'@media (min-width: 1400px)': {
				width: '1320px',
			},

			'& .risk_title': {
				color: '#E5E7EA',
				marginBottom: '16px',
				fontSize: '1rem',
				lineHeight: '20px',

				fontWeight: '500',

				'@media(min-width:992px)': {
					fontSize: '1.125rem',
					lineHeight: '22px',
				},
			},

			p: {
				fontSize: '0.875rem',
				lineHeight: props.theme.direction === 'rtl' ? '25px' : '17px',
				color: '#000',
				paddingTop: '1rem',
			},
			br: {
				display: 'block',
				content: '" "',
				marginTop: '0.5rem',
			},
		},
	},
}));

export const FooterContact = styled.div({
	display: 'flex',
	flexDirection: 'column-reverse',
	'.footer_platforms': {
		color: 'var(--color-text-1)',

		marginRight: '4px',
		fontWeight: 500,
		fontSize: '1rem',
		lineHeight: '20px',
		'.platform_items': {
			display: 'flex',
			gap: '1rem',
		},
	},
	'.contacts': {
		display: 'none',

		'@media(min-width:992px)': {
			display: 'block',
		},
	},
	'.contacts_item': {
		display: 'flex',
		alignItems: 'center',
		gap: '5px',
	},
	'.contacts_link': {
		fontWeight: 500,
		fontSize: '1rem',
		lineHeight: '20px',
		color: '#000',

		'@media(min-width:992px)': {
			display: 'none',
		},
	},
	'.title_desktop': {
		display: 'none',
	},
	'.platforms': {
		display: 'flex',
		justifyContent: 'space-between',

		'@media(min-width:992px)': {
			flexDirection: 'column',
		},
	},
	'@media(min-width: 576px)': {
		'.title_phone': {
			display: 'none',
		},
		'.title_desktop': {
			display: 'block',
		},
	},
	'@media(min-width: 992px)': {
		flexDirection: 'column',
		'.column_container': {
			'&:last-of-type': {
				paddingTop: '2.7rem',
			},
		},
	},
});
