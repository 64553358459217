import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useLocation, useNavigate } from 'react-router-dom';

//assets
import { NavbarContainer } from 'assets/css/layout/navbar';
import Logo from '../../assets/img/logo.svg';
import Hamburger from '../../assets/img/navbar/hamburger.svg';

// components
import Menu from '../../utils/navbar.config';
import HamburgerMenu from './hamburger-menu';

// uikit
import Button from 'cly-uikit/elements/button';
import NavbarItem from './navbar-item';

// MUI
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';

const Navbar = () => {
	const navigate = useNavigate();
	const location = useLocation();
	const menu = Menu();
	const { i18n, t } = useTranslation('navbar');
	const [hamburgerStatus, setHamburgerStatus] = useState<boolean>(false);
	let languages = i18n.options.resources ? Object.keys(i18n.options.resources) : ['en'];

	const langValueHandler = (event: any): any => {
		localStorage.setItem('forex_lang', event.target.value);
		i18n.changeLanguage(languages.includes(event.target.value) ? event.target.value : 'en');
		navigate(`/${event.target.value}${location.pathname.substring(3)}`, { replace: true });
	};

	const renderValue = (selectedValue: any) => {
		switch (selectedValue) {
			case 'en':
				return 'en';
			case 'fa':
				return 'fa';
			case 'ar':
				return 'ar';
			case 'fr':
				return 'fr';
			case 'ru':
				return 'ru';
			case 'es':
				return 'es';
			default:
				return '';
		}
	};

	const customMenuProps: any = {
		anchorOrigin: {
			vertical: 'bottom',
			horizontal: 'right',
		},
		transformOrigin: {
			vertical: 'top',
			horizontal: 'right',
		},
		getContentAnchorEl: null,
	};

	return (
		<NavbarContainer>
			<div className='dark_container'>
				<Button variant='ghost' className='ghosted' href='https://coinlocally.com/en/dashboard/forex'>
					{t('Try Demo')}
				</Button>
				<Button variant='outline' className='login' href='https://coinlocally.com/en/dashboard/forex'>
					{t('Login')}
				</Button>
				<Button variant='primary' className='open_live' href='https://coinlocally.com/en/dashboard/forex'>
					{t('Open Live')}
				</Button>
			</div>
			<div className='light_container'>
				<div className='left_field'>
					<Link to={`/${i18n.language}`}>
						<img src={Logo} alt='' />
					</Link>
					<div className='right_side'>
						<Select
							value={i18n.language}
							onChange={langValueHandler}
							renderValue={() => renderValue(i18n.language)}
							MenuProps={customMenuProps}
						>
							<MenuItem value='en'>English</MenuItem>
							<MenuItem value='fa'>Persian</MenuItem>
							<MenuItem value='ar'>Arabic</MenuItem>
							<MenuItem value='fr'>French</MenuItem>
							<MenuItem value='ru'>Russian</MenuItem>
							<MenuItem value='es'>Spanish</MenuItem>
						</Select>
						<img src={Hamburger} alt='' onClick={() => setHamburgerStatus(true)} />
						{hamburgerStatus && <HamburgerMenu status={hamburgerStatus} onClose={() => setHamburgerStatus(false)} />}
					</div>
					<div className='left_container'>
						{menu.map((item: any, index: any) => (
							<NavbarItem
								title={item.title}
								subMenu={item.subMenu ?? null}
								link={item.link}
								href={item.href}
								key={`navbar_item_${index}`}
							/>
						))}
					</div>
				</div>
				<div className='desktop_field'>
					<p>{t('Translate to :')}</p>
					<Select
						value={i18n.language}
						onChange={langValueHandler}
						renderValue={() => renderValue(i18n.language)}
						MenuProps={customMenuProps}
					>
						<MenuItem value='en'>English</MenuItem>
						<MenuItem value='fa'>Persian</MenuItem>
						<MenuItem value='ar'>Arabic</MenuItem>
						<MenuItem value='fr'>French</MenuItem>
						<MenuItem value='ru'>Russian</MenuItem>
						<MenuItem value='es'>Spanish</MenuItem>
					</Select>
				</div>
			</div>
		</NavbarContainer>
	);
};

export default Navbar;
