import { FC } from 'react';
import { HumburgerItemContainer } from '../../assets/css/layout/hamburger-menu';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

const HamburgerItem: FC<{
	title: string;
	link?: string;
	href?: string;
	subMenu: { title: string; link: string }[] | null;
	onOpen: (title: string) => void;
	open: string;
}> = ({ title, link, subMenu, onOpen, open, href }) => {
	const language = useTranslation().i18n.language;
	return (
		<HumburgerItemContainer>
			{!subMenu ? (
				href ? (
					<a href={href} className='simple_title'>
						{title}
					</a>
				) : (
					<Link className='simple_title' to={`/${language}${link}`}>
						{title}
					</Link>
				)
			) : (
				<>
					<div className={`title_dropdown ${open === title ? 'active' : ''}`} onClick={() => onOpen(open === title ? '' : title)}>
						<span>{title}</span>
						<i className='fal fa-angle-down'></i>
					</div>
					<div className={`sub_menu ${open === title ? 'open' : ''}`}>
						{subMenu.map((item, index) => (
							<Link to={`/${language}${item.link}`} key={`${title}_sub_menu_${title}_${index}`}>
								{item.title}
							</Link>
						))}
					</div>
				</>
			)}
		</HumburgerItemContainer>
	);
};

export default HamburgerItem;
