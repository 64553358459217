import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';

import en from './locales/en';
import fr from './locales/fr';
import ar from './locales/ar';
import es from './locales/es';
import fa from './locales/fa';
import ru from './locales/ru';
i18n.use(LanguageDetector)
	.use(initReactI18next)
	.init({
		fallbackLng: 'en',
		interpolation: {
			escapeValue: false,
		},
		supportedLngs: ['en', 'fr', 'ar', 'fa', 'ru', 'es'],
		resources: {
			en: {
				...en,
			},
			fr: {
				...fr,
			},
			fa: {
				...fa,
			},
			ru: {
				...ru,
			},
			es: {
				...es,
			},
			ar: {
				...ar,
			},
		},
	});

export default i18n;
