import { useTranslation } from 'react-i18next';

const Menu = () => {
	const { t } = useTranslation('footer');

	return [
		{
			title: t('Trading'),
			subMenu: [
				{
					title: t('Trading Accounts'),
					link: '/trading/trading-accounts',
				},
				{
					title: t('Deposit & Withdraw'),
					link: '/trading/deposit-withdraw',
				},
				{
					title: t('Trading Symbols'),
					link: '/trading/symbols',
				},
				{
					title: t('Economic Calendar'),
					link: '/trading/economic-calender',
				},
			],
		},
		{
			title: t('Platforms'),
			subMenu: [
				{
					title: t('Web Trading'),
					link: '/platforms/web-trading',
				},
				{
					title: t('Meta Trader 5'),
					link: '/trading/meta-trader-5',
				},
				{
					title: t('Meta Mobile Trader'),
					link: '/platforms/meta-mobile',
				},
			],
		},
		{
			title: t('Special Offers'),
			subMenu: [
				{
					title: t('IB Plan'),
					link: '/offers/affiliate',
				},
				{
					title: t('Bonus Plan'),
					link: '/offers/bonus-plan',
				},
			],
		},
		// {
		// 	title: t('Education'),
		// 	subMenu: [
		// 		{
		// 			title: t('Meta Trader Desktop Training'),
		// 			link: '/education/meta-trader-desktop',
		// 		},
		// 		{
		// 			title: t('Meta Trader Mobile Training'),
		// 			link: '/education/meta-trader-mobile',
		// 		},
		// 		{
		// 			title: t('How to Open an Account'),
		// 			link: '/education/how-to-open-account',
		// 		},
		// 		{
		// 			title: t('How to Transfer Funds'),
		// 			link: '/education/how-to-transfer-funds',
		// 		},
		// 		{
		// 			title: t('How to Make Deposit'),
		// 			link: '/education/how-to-deposits',
		// 		},
		// 		{
		// 			title: t('How to Make Withdraw'),
		// 			link: '/education/how-to-withdrawals',
		// 		},
		// 		{
		// 			title: t('How to Make Transfer'),
		// 			link: '/education/how-to-transfer',
		// 		},
		// 		{
		// 			title: t('KYC Rules and Levels'),
		// 			link: '/education/kyc-rules',
		// 		},
		// 	],
		// },
		{
			title: t('About Us'),
			isNavOnly: true,
			link: '/about-us',
		},
		{
			title: t('Terminal Web Trading'),
			isNavOnly: true,
			href: 'https://web.coinlocally.forex/terminal',
		},
		{
			title: t('exchange'),
			isNavOnly: true,
			href: 'https://coinlocally.com',
		},
	];
};

export default Menu;
