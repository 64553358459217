import React, { useState } from 'react';

//assets
import { HamburgerContainer } from '../../assets/css/layout/hamburger-menu';
import arrow_left from '../../assets/img/navbar/arrow_left.svg';
import download_light from '../../assets/img/bottom-download/download_light.svg';
import clyc_logo_light from '../../assets/img/bottom-download/clyc_logo_light.svg';

//components
import HamburgerItem from './hamburger-item';
import Menu from '../../utils/navbar.config';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

const HamburgerMenu: React.FC<{ status: boolean; onClose: () => void }> = ({ status, onClose }) => {
	const { t } = useTranslation('navbar');
	const [innerStatus, setInnerStatus] = useState<boolean>(status);
	const onCloseHandler = () => {
		setInnerStatus(false);
		setTimeout(() => {
			onClose();
		}, 300);
	};

	const menu = Menu();
	const [opendHamburgerMenuItem, setOpendHamburgerMenuItem] = useState<string>('');
	const language = useTranslation().i18n.language;

	return (
		<HamburgerContainer>
			<div className={`dark_background ${innerStatus ? 'active' : 'deactive'}`} onClick={onCloseHandler}></div>
			<aside className={innerStatus ? 'active' : 'deactive'}>
				<div className='header'>
					<img src={arrow_left} alt='' className='back' onClick={onCloseHandler} />
					{menu.map((item: any, index: any) => (
						<HamburgerItem
							title={item.title}
							link={item.link}
							href={item.href}
							subMenu={item.subMenu ?? null}
							key={`${item.title}_${index}`}
							open={opendHamburgerMenuItem}
							onOpen={title => setOpendHamburgerMenuItem(title)}
						/>
					))}
				</div>
				<div className='divider'></div>
				<HamburgerItem
					title={t('Try Demo')}
					link='/'
					subMenu={null}
					open={opendHamburgerMenuItem}
					onOpen={title => setOpendHamburgerMenuItem(title)}
				/>

				<Link className='download_container' to={`/${language}/download`}>
					<div className='left'>
						<img src={clyc_logo_light} alt='' />
						<div className='bottom_download_title'>Coinlocally Forex App</div>
						<div className='bottom_download_subtitle'>{t('Trade anytime, anywhere')}</div>
					</div>
					<div className='right'>
						<div className='bottom_download_right_item'>
							<img src={download_light} alt='' />
						</div>
					</div>
				</Link>
			</aside>
		</HamburgerContainer>
	);
};

export default HamburgerMenu;
