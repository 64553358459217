import { FC } from 'react';
import { useTranslation } from 'react-i18next';

import { NavbarItemContainer } from '../../assets/css/layout/navbar';
import { Link } from 'react-router-dom';

const NavbarItem: FC<{
	title: string;
	link?: string;
	href?: string;
	subMenu: { title: string; link: string }[] | null;
}> = ({ title, link, subMenu, href }) => {
	const language = useTranslation().i18n.language;

	return (
		<NavbarItemContainer>
			{!subMenu ? (
				href ? (
					<a href={href} className='simple_title'>
						{title}
					</a>
				) : (
					<Link className='simple_title' to={`/${language}${link}`}>
						{title}
					</Link>
				)
			) : (
				<>
					<div className={`title_dropdown`}>
						<span>{title}</span>
						<i className='fal fa-angle-down'></i>
					</div>
					<div className={`sub_menu`}>
						{subMenu.map((item, index) => (
							<Link to={`/${language}${item.link}`} key={`nav_${title}_sub_menu_${title}_${index}`}>
								{item.title}
							</Link>
						))}
					</div>
				</>
			)}
		</NavbarItemContainer>
	);
};

export default NavbarItem;
