import no_commission from './no-commissions.json';
import about_us from './about-us.json';
import account_type from './account_type.json';
import affiliate from './affiliate.json';
import ai_multi_language from './ai-multi-language.json';
import bonus_plan from './bonus-plan.json';
import deposit from './deposit.json';
import footer from './footer.json';
import how_to_deposits from './how-to-deposits.json';
import how_to_transfer from './how-to-transfer.json';
import how_to_withdrawals from './how-to-withdrawals.json';
import kyc_rules from './kyc-rules.json';
import landing from './landing.json';
import low_content from './low-content.json';
import meta_trade from './meta_trade.json';
import meta_trader_desktop from './meta-trader-desktop.json';
import meta_trader_mobile from './meta-trader-mobile.json';
import navbar from './navbar.json';
import offers from './offers.json';
import open_account from './open-account.json';
import symbols from './symbols.json';
import transfer_funds from './transfer-funds.json';
import web_trading from './web-trading.json';
import web_trading2 from './web_trading.json';
import withdraw from './withdraw.json';

const scopes = {
	account_type,
	about_us,
	affiliate,
	ai_multi_language,
	bonus_plan,
	deposit,
	footer,
	how_to_deposits,
	how_to_transfer,
	how_to_withdrawals,
	kyc_rules,
	landing,
	low_content,
	meta_trade,
	meta_trader_desktop,
	meta_trader_mobile,
	navbar,
	offers,
	open_account,
	symbols,
	transfer_funds,
	web_trading,
	web_trading2,
	withdraw,
	no_commission,
};

export default scopes;
