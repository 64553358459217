import { Suspense, lazy, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { BrowserRouter, Outlet, Route, Routes, useLocation, useParams } from 'react-router-dom';

// components
import Footer from './components/layout/footer';
import Loader from './components/layout/loader';
import Navbar from './components/layout/navbar';

// assets
import 'bootstrap/dist/css/bootstrap-grid.min.css';
import 'cly-uikit/css/font/oswald.min.css';
import 'cly-uikit/css/font/product-sans.min.css';
import 'cly-uikit/css/global.min.css';

// MUI
import { Global, ThemeProvider } from '@emotion/react';
import { createTheme } from '@mui/material/styles';

// routes
const Landing = lazy(() => import('./routes/landing'));
const DepositWithdraw = lazy(() => import('./routes/deposit-withdraw'));
const WebTrading = lazy(() => import('./routes/web-trading'));
const MetaTrader5 = lazy(() => import('./routes/meta-trader-5'));
const MetaMobile = lazy(() => import('./routes/meta-mobile'));
const TradingSymbols = lazy(() => import('./routes/trading-symbols'));
const AccountType = lazy(() => import('./routes/account-type'));
const EconomicCalender = lazy(() => import('./routes/economic-calender'));
const BonousPlan = lazy(() => import('./routes/BonousPlan'));
const AboutUs = lazy(() => import('./routes/about-us'));
const AiMultiLanguage = lazy(() => import('./routes/ai-multilanguage'));
const IBPlan = lazy(() => import('./routes/ib-plan'));
const MetaTraderDesktop = lazy(() => import('./routes/education/meta-trader-desktop'));
const TransferFundsHelp = lazy(() => import('./routes/education/how-to-transfer-funds'));
const DepositHelp = lazy(() => import('./routes/education/how-to-deposit'));
const OpenAccountHelp = lazy(() => import('./routes/education/how-to-open-account'));
const TransferHelp = lazy(() => import('./routes/education/how-to-transfer'));
const WithrawHelp = lazy(() => import('./routes/education/how-to-withraw'));
const KycRules = lazy(() => import('./routes/education/kyc-rules'));
const MetaTraderMobile = lazy(() => import('./routes/education/meta-trader-mobile'));
const NoCommission = lazy(() => import('./routes/no-commission'));
const ChallengeLanding = lazy(() => import('pages/landing/challenge'));

function App() {
	const { language } = useTranslation().i18n;
	const theme = (mode: 'light' | 'dark', direction: 'rtl' | 'ltr', lang: string | null) => ({
		direction,
		mode,
		lang,
	});

	const direction = language === 'fa' || language === 'ar' ? 'rtl' : 'ltr';
	const themeProvider = createTheme(theme('light', direction, language));

	useEffect(() => {
		document.dir = direction;
	}, [direction]);

	useEffect(() => {
		localStorage.setItem('forex_lang', language);
	}, [language]);

	function LanguageHandler() {
		const { lng } = useParams();
		const { i18n } = useTranslation();
		const location = useLocation();
		let language = i18n.language;
		let languages = i18n.options.resources ? Object.keys(i18n.options.resources) : ['en'];

		if (i18n.language.includes('-')) {
			language = i18n.language.split('-')[0];
		}

		useEffect(() => {
			if (!lng || lng.length !== 2) {
				window.location.replace(language + location.pathname);
			}
		}, [location.pathname]);

		useEffect(() => {
			if (lng?.length === 2 && lng !== i18n.language) {
				if (languages.includes(lng)) {
					i18n.changeLanguage(lng);
				} else {
					window.location.replace(location.pathname.replace(lng, language));
				}
			}
		}, [lng]);

		return <Outlet />;
	}
	return (
		<ThemeProvider theme={themeProvider}>
			<BrowserRouter>
				<Suspense fallback={<Loader />}>
					<Global
						styles={{
							body: {
								direction,
							},
							'body *': {
								fontFamily: direction === 'rtl' ? 'Vazirmatn !important' : '',
							},
						}}
					/>
					<Navbar />
					<Routes>
						<Route path='' element={<LanguageHandler />} />
						<Route path=':lng/' element={<Landing />} />
						<Route path=':lng/trading'>
							<Route path='deposit-withdraw' element={<DepositWithdraw />} />
							<Route path='meta-trader-5' element={<MetaTrader5 />} />
							<Route path='symbols' element={<TradingSymbols />} />
							<Route path='trading-accounts' element={<AccountType />} />
							<Route path='economic-calender' element={<EconomicCalender />} />
						</Route>
						<Route path=':lng/platforms'>
							<Route path='meta-mobile' element={<MetaMobile />} />
							<Route path='web-trading' element={<WebTrading />} />
						</Route>
						<Route path=':lng/offers'>
							<Route path='affiliate' element={<IBPlan />} />
							<Route path='bonus-plan' element={<BonousPlan />} />
						</Route>
						<Route path=':lng/landing'>
							<Route path='ai-multilanguage' element={<AiMultiLanguage />} />
							<Route path='challenge' element={<ChallengeLanding />} />
						</Route>
						<Route path=':lng/about-us' element={<AboutUs />} />
						<Route path=':lng/education'>
							<Route path='meta-trader-desktop' element={<MetaTraderDesktop />} />
							<Route path='how-to-transfer-funds' element={<TransferFundsHelp />} />
							<Route path='how-to-open-account' element={<OpenAccountHelp />} />
							<Route path='how-to-withdrawals' element={<WithrawHelp />} />
							<Route path='how-to-deposits' element={<DepositHelp />} />
							<Route path='how-to-transfer' element={<TransferHelp />} />
							<Route path='kyc-rules' element={<KycRules />} />
							<Route path='meta-trader-mobile' element={<MetaTraderMobile />} />
						</Route>
					</Routes>
					<Footer />
				</Suspense>
			</BrowserRouter>
		</ThemeProvider>
	);
}

export default App;
